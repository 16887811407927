h2.gmo-text-light-grey {
    text-align: center;
    padding-top: 40px;
    margin: 0;
}

.contact-form{
    min-width: 300px;
    width: 50%;
}

.contact-submit{
    font-size: 13px!important;
}

.question {
    text-align: left;
    margin: 0 auto;
    min-height: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    position: inherit;
    display: grid;
}