.list-admin {
    width: 80%;
    display: block;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

.editable-list {
    width: 100%;
    padding-top: 50px;
}

.editable-list a {
    color: #000;

}

.list-remove-button {
    font-size: small;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 8px;
    border: none;
    color: #000;
    background: rgb(230, 229, 229);
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    height: 30px;
    cursor: pointer;
}

.list-remove-button:hover{
    opacity: 0.6;
}

@media (max-width: 900px) {


    table {
        padding-left: 0;
        width: 80% !important;
    }

}

table {
    width: 100%;
    margin: 0 0 1.5em;
    margin-bottom: 1.5em;
    background-color: transparent;
    padding-left: 20px;
    text-align: center;
}


.editable-table th,
.editable-table td {
    border-bottom: 0px, solid, #fff !important;
    border: 1px solid #e5e5e5;
}

.editable-table,
.editable-table td,
.editable-table th {
    padding-top: 12px;
}

thead {
    display: table-header-group;
    color: #666;
}

.editable-table>thead>tr,
.editable-table>tbody>tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

.editable-table tr:last-child td {
    padding-right: 0;
    padding-left: 0;
    background-color: white;
}

table td.actions {
    text-align: right;
}

.editable-table td,
.editable-table th {
    border: 1px solid #e5e5e5;
}

.add-new-entry {
    text-align: right;
    cursor: pointer;
}

.editable-button {
    text-align: center;
    color: white !important;
    margin-right: 0px;
    border: none;
    border-radius: 4px;
    background-color: rgba(213, 93, 7, 0.63);
    cursor: pointer;
    font-size: large;
    padding: 5px;
    margin-left: 20px;
}

.add-new-item {
    width: 200px;
    text-align: center;
    color: white !important;
    margin-right: 0px;
    border: none;
    border-radius: 4px;
    background-color: rgba(213, 93, 7, 0.63);
    cursor: pointer;
    font-size: large;
    padding: 10px;
}

.note h2,
.note h3 {
    color: red;
    text-align: center;

}

.list-variants-select {
    max-width: 500px;
}