.product-details {
  padding-top: 100px;
}

.product-container {
  width:90%;
  max-width: 1600px;;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

.product-heading {
  text-align: center;
  color: black;
  padding-bottom: 40px;
}

.product-breadcrumbs {
  padding-left: 20px;
}

.href-product-breadcrumbs {
  color: black;

}

._t1897 {
  object-fit: contain !important;
}

.product-images {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  display: contents;
}

.product-options {
  position: relative;
  padding: 30px;
  flex-grow: 1;
  min-width: 0;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.product-select-label,
.final-price {
  padding: 10px;
}

#images_options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  margin: 0 auto;
  row-gap: 20px;
  grid-auto-flow: dense;
  /* Ensure items fill both rows and columns */
  min-height: 0;
  overflow: hidden;
}

.variant-image-div {
  grid-column: span 1;
  grid-row: span 2;
  object-fit: cover;
  cursor: pointer;
  width:100px;
}

.variant-image-div p {
  display: none;
}

.variant-image {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.variant-image-div:hover {
  background-color: #adadad3a;
}

.variant-image-div p {
  font-size: smaller;
  color: #1e1e1d;
}

.selected {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  ;
}

.product-count {
  padding: 10px;
}

.product-select,
#quantity,
.add-to-cart {
  width: 100%;
  -moz-box-align: center;
  align-items: center;
  cursor: default;
  -moz-box-pack: justify;
  justify-content: space-between;
  outline: 0px !important;
  position: relative;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  color: rgb(9, 9, 9);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.final-price {
  border-top: 1px solid #ddd;
  margin-top: 40px;
}

.price {
  float: right;
}

.final {
  font-size: x-large;
}

.product-timeline,
.product-delivery-info {
  margin-top: 20px;
  padding-top: 10px;
  text-align: right;
  background: rgba(150, 90, 46, 0.10);
  padding-bottom: 10px;
  padding-right: 20px;
  border-radius: 4px;
}

.product-delivery-info .smaller {
  font-size: small;
}

.add-to-cart {
  margin-top: 40px;
  border-color: rgba(150, 90, 46, 0.63);
  background-color: rgba(150, 90, 46, 0.63);
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}

.images-carousel > div > div > ul {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.add-to-cart:hover {
  opacity: 0.6;
}

@media (min-width: 900px) {
  .images-carousel {
    width: 60%;
  }

  .product-options {
    max-width: 800px;
    width: 40vw;
  }
}


@media (max-width: 900px) {
  .images-carousel {
    max-width: 100vw !important;
  }

  .product-options {
    width: 90vw;
    min-width: 350px;
  }

  #images_options {
    grid-template-columns: repeat(1, 1fr);
  }
}

.product-description-container {
  padding: 50px;
  white-space: pre-wrap;
}

.product-tab a,
.description-tab a {
  color: black;
}

.product-tabs ul.tabs {
  float: left;
  width: 100%;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.product-tabs ul.tabs li,
.product-tabs ul.tabs li.active {
  border: none;
  background: none;
}

.product-tabs ul.tabs li {
  display: block;
  float: left;
  margin: 0;
  margin-bottom: 0px;
  margin-bottom: -1px;
}

.product-tabs ul.tabs li {
  display: inline-block;
  margin-right: 1em;
}

.product-tabs ul.tabs li.active a:hover {
  border: 1px solid #ddd;
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-color: transparent;
  background: #fff;
  cursor: default;
}

.product-tabs ul.tabs li.active {
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  color: #555;
  background-color: #fff;
  cursor: default;
}

.product-footer-container {
  background-color: rgba(150, 90, 46, 0.63);
  color: white;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 500px;
  width: 100%;
}

.popup-text {
  padding-bottom: 10px;
}

.product-footer-container:hover {
  opacity:0.7;
}

.product-footer-description {
  padding: 40px;
  margin-top: auto;
  margin-bottom: auto
}

.img-product-footer-container {
  overflow: hidden !important;
  width: 100%
}