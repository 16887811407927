.gmo-padding-16 {
    padding-top: 16px !important;
    padding-bottom: 16px !important
}

.gmo-padding-24 {
    padding-top: 24px !important;
    padding-bottom: 24px !important
}

.gmo-input {
    padding: 8px;
    display: block;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%
}
#contact{
    padding-top:30px;
}

.contact {
    position: relative;
    z-index: 0;
    width: 100%;
    background-color: #ffffffed;
}

textarea {
    width: 100%;
}

.contact-flexi {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

}

.contact-adress {
    padding-top: 0px;
}

.contact-form__container {
    padding-bottom: 20px;
  }