.cart {
    padding-top: 70px;
    min-height: 60vh;
}

.divider {
    margin-top: 20px;
    margin: 0;
    margin-top: 0px;
    border-top: 1px solid #eaeaea;
    height: 1px;
    padding-bottom: 20px;
}

.cart-empty {
    text-align: center;
    padding-bottom: 50px;
}


.cart-sending {
    position: fixed;
    background-color: #deb88785;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh
  }
  

.remove-button {
    font-size: small;
    width: 30px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 8px;
    border: none;
    color: #000;
    background: rgb(230, 229, 229);
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

th.product-quantity {
    min-width: 50px;
}

input[type="submit"] {
    width: 70%;
    margin-bottom: 40px !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 40px;
    border-color: rgba(150, 90, 46, 0.63);
    background-color: rgba(150, 90, 46, 0.63);
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    border:none;
}

input[type="submit"],
button[type="submit"],
.cart-backward {
    padding: 15px 45px;
    border-radius: 8px;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 40px;
}

.cart-back-button {
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    padding-bottom: 150px;
    align-content: center;
    display: grid;
}

.cart-grid {
    text-align: center;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0 auto;
    grid-auto-flow: dense;
    min-height: 0;
}


@media (max-width: 900px) {
    .cart-grid {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        padding: 10px;
    }

    table {
        padding-left: 0;
        width: 80% !important;
    }

    .cart-total {
        width: 90%;
    }

    .cart-input {
        width: 80% !important;
    }
}

.cart-popup {
    width: 25vw;
    height: fit-content;
    padding: 20%;
    position: initial;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.popup.cart-popup {
    width: max-content;
    background: none;
    box-shadow: none;
}
.checkbox-container{
    width:85%;
    padding-left: 30px;
}

.gdpr-checkbox,
.rules-checkbox {
    text-align: left;

    margin: 0 auto;
    min-height: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    position: inherit;
}

.gdpr-checkbox label,
.rules-checkbox label {
    padding-left: 10px;
   
}

table {
    width: 100%;
    margin: 0 0 1.5em;
    margin-bottom: 1.5em;
    background-color: transparent;
    padding-left: 20px;
}

.cart-with-products {
    width: 100vw;

}

.cart-form {
    display: block;
    align-content: center;
    width: 100%;
    position: inherit;
}


.shop_table th,
.shop_table td {
    border-bottom: 0px, solid, #fff !important;
    border: 1px solid #e5e5e5;
}

.shop_table,
.shop_table td,
.shop_table th {
    padding-top: 12px;
}

thead {
    display: table-header-group;
    color: #666;
}

.shop_table>thead>tr,
.shop_table>tbody>tr:nth-of-type(2n) {
    background-color: #f9f9f9;
}

.shop_table tr:last-child td {
    padding-right: 0;
    padding-left: 0;
    background-color: white;
}

table td.actions {
    text-align: right;
}

#comments input[type="submit"]:hover,
button[type="submit"]:hover {
    background: rgba(17, 17, 17, .8);
}

button[name="update_cart"] {
    width: 40%;
}

#comments input[type="submit"],
button[type="submit"] {
    float: right;
    clear: both;
    margin-top: 20px;
    margin-right: 40px;
    margin-right: 0;
    font-size: 18px;
}

.qty {
    width: 80% !important;
}

.cart-total {
    text-align: right !important;
    position: inherit !important;

}

.spinner-container {
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-bottom: 50px;
}

.spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    border: 4px solid #393939;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.contact-form__header,
.cart-header {
    font-size: x-large;
    padding-top: 0px !important;
    margin-top: 0px !important;
}

.submit-button {
    padding: 20px;
}

.send-cart-button:hover {
    opacity: 0.6;
}

.send-cart-button {
    margin-top: 40px;
    border: none;
    background-color: rgba(150, 90, 46, 0.63);
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: larger;
}

#company-data,
#delivery-data {
    width: 80%;
    padding: 40px;
    padding-top: 0px !important;
    margin-left: auto;
    margin-right: auto;
}

.add-company-data {
    color: red;
}

.cart-input {
    padding: 8px;
    display: block;
    border: 0.2px solid #ddd !important;
    width: 100%;
    border-radius: 0;
    font-size: var(--wp--preset--font-size--small);
    padding: .9rem 1.1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}



p.validation-message.small {
    font-size: small;
    padding-bottom: 0;
    padding-top: 0;
}

.validation-message {
    font-size: large;
    color: red;
    text-align: center;
    padding-bottom: 20px;
    width: 100%;
    padding-top: 20px;
}

.red {
    color: red;
    border-color: red !important;
    background-color: #f2cbcb;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.popup {
    position: fixed;
    padding: 1rem 3rem;
    padding-top: 1rem;
    background: white;
    max-width: 400px;
    padding-top: 2rem;
    border-radius: 20px;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
    -webkit-animation: fadeIn 1s ease both;
    animation: fadeIn 1s ease both;

}

.close-popup {
    position: absolute;
    filter: grayscale(1);
    border: none;
    background: white;
    top: 0px;
    right: 0px;
    transition: ease filter, transform 0.3s;
    cursor: pointer;
    transform-origin: center;
}

.order-timeline,
.delivery-total {
    margin-top: 20px;
    padding-top: 10px;
    text-align: center;
    background: rgba(150, 90, 46, 0.10);
    padding-bottom: 10px;
    padding-right: 20px;
    border-radius: 4px;
}

.delivery-total {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: right;
    margin-left: 20px;
}

.cart a {
    color: black;
}

td a {
    text-decoration: underline;
}