.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.lightbox-content {
    width: 90%;
    max-height: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #000;
    padding: 20px;
    overflow: scroll;
    margin-top: 50px;
}

.lightbox-content img {
    max-width: 100%;
    height: 90%;
}

.lightbox-button{
    cursor: pointer;
    position: absolute;
    color: white;
    background-color: #646363;
    border: none;
}

.close-button {
    right: 0;
    top: 0;
    width: 5%;
}

.lightbox-button {
    width: 8%;
}

.prev {
    top: 50%;
    left: 0;
    height: 40px;
}

.next {
    top: 50%;
    right: 0;
    height: 40px;
}