body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat", sans-serif;

}

.error {
  color: red;
}

.success {
  font-size: large;
  background-color: #deb887;
  color: white;
  position: absolute;
  width: 55%;
  height: 100%;
  align-content: center;

}
.succesMessage{
  width:50%;
  margin-left: auto;
  margin-right: auto;
}