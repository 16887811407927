.image-gallery {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .image-gallery img {
    width: 35%; /* You can adjust the width as needed */
    max-width: 100%; /* To ensure images don't exceed the container's width */
    object-fit: contain;
  }

  .bestFurnitures{
    overflow: hidden;
    padding-left: 0  !important;
    padding-right: 0 !important;
  }