
#login{
    width: 100%;
    max-width: 100%;
}

.login-form {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .login-form .error{
    text-align: center;
  }

  #logout-button {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    line-height: 1.5;
    margin-top: 15px;
    margin-left: 10px;
    width: 300px;
    height: 30px;
    position: fixed;
    z-index: 1111;
    border: none;
    background-color: rgba(150, 90, 46, 0.93);
    color: white;
    cursor: pointer;
  }

  #logout-button:hover {
    opacity: 0.6;
  }

  .admin-content {
    display: block;
    padding-top: 100px;
    width: 100%;
  }