.footer {
    background-color: black;
    width: 100vw;
    position: absolute;
    padding-top: 40px;
    padding-bottom: 40px;
    color: white;
}

.container {
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
}

.footer-row{
    justify-content: space-around;
}
.col-md-3 {
    width: 25%;
}


.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12 {
    float: left;
}

.col-sm-6 {
    width: 50%;
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12 {
    float: left;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 20vw;
    min-width: 250px;
}

.menu-item a {
    color: white;
}

li{
    list-style-type: none 
}

.footer-list{
    padding-left: 0px;
}