#edit-form {
  width: 80%;
  padding: 40px;
  padding-top: 0px !important;
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  display: grid;
}


.form-input {
  padding: 8px;
  display: block;
  border: 0.2px solid #ddd !important;
  width: 100%;
  border-radius: 0;
  font-size: var(--wp--preset--font-size--small);
  padding: 0.9rem 1.1rem;
}

.variants-select {
  padding-right: 20px;
  padding-top: 40px;
}

.list-collection-select {
  width: 100%;
  align-items: center;
  cursor: default;
  justify-content: space-between;
  outline: 0px !important;
  position: relative;
  transition: all 100ms ease 0s;
  background-color: rgb(255, 255, 255);
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}

.form-item {
  padding: 8px;
  display: block;
}

.form-textarea {
  height: auto;
  min-height: 100px;
  /* You can adjust this value based on your design */
  overflow-y: hidden;
  /* Hide the vertical scrollbar */
}

.input-admin-label {
  font-size: large;
  font-weight: 800;
  padding-top: 20px;
  padding-bottom: 20px;
}

.admin-edit-input {
  padding-top: 20px;
  padding-bottom: 20px;
}

.saved {
  width: 80%;
  height: 40%;
  max-width: 100%;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(150, 90, 46, 0.73);
  text-align: center;
  vertical-align: center;
  color: white;
  font-size: x-large;
}

.close-popup {
  position: absolute;
  filter: grayscale(1);
  border: none;
  background: white;
  top: 0px;
  right: 0px;
  transition: ease filter, transform 0.3s;
  cursor: pointer;
  transform-origin: center;
}

input[type="submit"] {
  width: 70%;
  margin-bottom: 40px !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 40px;
  border-color: rgba(150, 90, 46, 0.63);
  background-color: rgba(150, 90, 46, 0.63);
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

input[type="submit"],
button[type="submit"] {
  padding: 15px 45px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
}

.flex-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.product-images {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0;
  display: contents;
}

.thumbnail {
  width: 200px;
}

.edit-remove {
  width: 100%;
}

.thumbnail-container {
  padding: 10px;
}

input[type="file"] {
  font-size: small;
  width: 100%;
  margin-left: 2px;
  margin-right: 2px;
  display: block;
  border: solid, 1px, black;
  color: #000;
  background: #965a2e1a;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.empty-images {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #c0886125;
}

.image-uploader {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
}

td.option {
  background: #f0f0f0 !important;
}

button.draft {
  float: right;
  margin-right: 100px;
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
  width:30%;
}

button.save-changes{
  width:70%;
  background-color: rgba(210, 105, 30, 0.553);
}

.button-container {
  display: flex; /* Use flexbox */
}

/* Optionally, you can add some spacing between the buttons */
.button-container button {
  margin-right: 10px; /* Adjust as needed */
}

.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}