.navbar {
    width: 100vw;
}

.gmo-hide-small {
    display: "" !important;
}

.nav-sidebar {
    text-align: right;
    padding-right: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    letter-spacing: 3px;
    overflow: visible;
}

img.logo {
    width:60px;
    position: absolute;
    top:10px;
    left: 20px;
}
@media (max-width: 768px) {
    .navbar-fixed-top .navbar-collapse {
        background: rgba(10, 10, 10, .9) !important;
    }
}

@media screen and (max-width: 768px) {
    .navbar-fixed-top .navbar-collapse {
        float: left;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navbar-fixed-top .navbar-collapse {
        float: left;
        position: absolute;
        z-index: 9999999;
        top: 100%;
        left: 0;
        width: 100%;
        background: #1b1b1b;
    }
}

.navbar-fixed-top .navbar-collapse {
    width: 100vw;
    text-align: center;
    color: white;
    font-size: xx-large;
    top: 0;
    position: fixed !important;
}

.navbar-collapse,
.barsContainer {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch;
}

.barsContainer {
    display: inline-block;
    cursor: pointer;
    padding-top: 10px;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 5px;
    margin: 6px 0;
    transition: 0.4s;
    background-color: white;
}

.bar1.change {
    transform: translate(0, 11px) rotate(-45deg);
}

.bar2.change {
    opacity: 0;
}

.bar3.change {
    transform: translate(0, -11px) rotate(45deg);
}

/* .collapse {
    display: none;
  } */


.navbar-fixed-top .navbar-collapse,
.navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
}

@media (min-width: 769px) {
    .navbar-fixed-top .navbar-collapse {
        display: hidden;
    }
}

@media (min-width: 769px) {
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
    }
}

@media (min-width: 769px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}


@media (max-width: 768px) {
    .navbar-toggle {
        display: table-cell;
        float: none;
        width: 44px;
        vertical-align: middle;
        cursor: pointer;
    }

    .gmo-bar-item.gmo-button.gmo-padding-large {
        display: list-item;
        border-bottom: 1px solid rgba(73, 71, 71, .15) !important;
    }

    .nav-sidebar {
        padding-top: 40px;
        background-color: #0000009c;
    }

    .nav-sidebar {
        display: none;
    }

    .nav-sidebar.show {
        display: inline;
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}


.navbar-custom .navbar-toggle .icon-bar {
    background: #fff;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.navbar-cart {
    border: none;
    display: inline-block;
    padding: 8px 16px;
    padding-left: 0px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    text-align: center;
    cursor: pointer;
    color: white;
    padding-right: 100px;
}

.navbar-cart:hover {
    opacity: 0.8;
}


.cart-contents {
    color: white;
}

.icon-basket{
    color: white;
    font-size: medium !important;
}