.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 80px;
    width: 90%;
    margin: 0 auto;
    grid-auto-flow: dense;
    /* Ensure items fill both rows and columns */
    min-height: 0;
    /* Allow items to break out of the container's height */
}

.grid-item {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
/* Product Images */
.grid-item.main {
    grid-column: span 4;
    grid-row: span 4;
  }

/* Other galleries */
.large {
    grid-column: span 2;
    grid-row: span 2;
}

.gallery {
    position: relative;
    z-index: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 90vh;
}

.gallery-heading {
    text-align: center;
    color: black;
}

.image-description {
    text-align: center;
  }