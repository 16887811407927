#how-to-buy-section{
    position: relative;
    z-index: 0;
    width: 100%;
    background-color: #000000bf;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 40px;
    overflow: hidden;
}

.col-buy{
    width: 20vw;
    min-width: 300px;
    padding-left: 30px;
    padding-right: 30px;
    color: #ffffff !important;
}

.how-to-buy-row{
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
    width: 100vw;
    overflow: hidden;
}

.how-to-buy-header{
    padding-bottom: 20px;
    color: #fff;
}