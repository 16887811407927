.privacy h1,
.rules h1, 
.order-content h1,
.how-to-care h1{
    padding-top: 100px;
    margin-top: 0px;
    padding-bottom: 40px;
    text-align: center;
}

.privacy,
.rules, 
.order-content,
.how-to-care{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
}

.how-to-care li{
    list-style-type: circle;
}