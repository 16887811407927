

.gmo-half img {
    width: 33%;
}

.home-slider-overlay {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .3;
    background: rgba(0, 0, 0, 1);
    font: 400 13px/1.8 "Open Sans", sans-serif;
    width: 100%;
    height: 100%;
    margin: 0 auto;

}

.caption {
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    color: #fff;
    overflow: hidden;
}

.caption-content {
    text-align: center;
    vertical-align: middle;
    width: 100vw;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.hs-title-size-1 {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    letter-spacing: 4px;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.hs-title-size-4 {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 10px;
}

.font-alt {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.btn.btn-border-w {
    border: 1px solid rgba(255, 255, 255, .75);
    color: #fff;
    background: 0 0;
}

.btn.btn-round {
    border-radius: 2px;
}

.bg-dark a {
    color: #aaa;
}

.btn {
    padding: 8px 37px;
    border-radius: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.btn,
.form-control,
.form-control:focus,
.form-control:hover,
.navbar-custom .children,
.navbar-custom .sub-menu,
.panel,
.progress,
.well {
    box-shadow: none;
}

.btn,
a {
    -webkit-transition: all 125ms ease-in-out 0s;
    transition: all 125ms ease-in-out 0s;
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    color: #111;
}

a {
    color: #337ab7;
    text-decoration: none;
}

.flex-active-slide {
    background-image: url("https://www.gmo-design.pl/wp-content/uploads/2018/05/SAN-Ki-100x45x100cm-04.jpg");
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    font: 400 13px/1.8 "Open Sans", sans-serif;
    width: 100vw;
    height: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.home-section {
    position: relative;
    z-index: 0;
    width: 100%;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 99vh;
}

.gmo-content {
    position: relative;

}

.about-brand {
    background-color: #fff;
    text-align: center;
    padding-bottom: 50px;
}