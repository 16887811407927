.shop {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}

.loading {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 40px;
    text-align: center;
}

.empty-products-view {
    width: 100%;
    padding: 50px;
    font-size: larger;
    text-align: center;
}

.filter-container {
    width: 20%;
    padding-left: 10%;
}

@media screen and (max-width: 768px) {
    .filter-container {
        width: 100%;
        background-color: #e0c39a42;
        padding-left: 5px;

    }
    .shop {
        width: 90%;
        max-width: 100%;
        padding-top: 0;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
    }
    ul.tabs {
        font-size: small;
    }
}

.products-view {
    width: 70%;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 100px;
}

.shop-product-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.shop-thumbnail {
    width: 100%;
    height: 262px;
    object-fit: scale-down;
    overflow: hidden;
}

.product-info {
    flex-grow: 1;
    margin: 15px 0 5px;
    color: #111;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.product-price {
    font-size: 10px;
    font-weight: 300;
    color: #444
}

.product-link {
    color: black;
}

.product-link:hover {
    opacity: 0.4;
}

.filter-name {
    margin: 0 0 15px;
    padding: 0 0 10px;
    border-bottom: 1px dotted #c2c2c2;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.filter-by-category,
.filter-by-collection {
    padding-bottom: 20px;
}

.clickable-category,
.clickable-collection {
    cursor: pointer;
}

.bold {
    font-weight: 1000;
}